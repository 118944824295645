import React, { useEffect, useRef, useState } from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import { AUTHENTICATION_TOKEN } from "../../constants/application"
import ApplicationLayout from "../../layouts/ApplicationLayout"

const DvirAdmin = ({ flutterApp, currentUser }) => {
  const hostElementRef = useRef(null)

  useEffect(() => {
    if (flutterApp && hostElementRef.current) {
      const dvirData = {
        auth_token: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN),
        user: currentUser,
        baseURL: window.location.origin
      }
      flutterApp.addView({
        hostElement: hostElementRef.current,
        viewConstraints: {
          minHeight: 0,
          maxHeight: window.innerHeight - 100
        },
        initialData: {
          widget: "dvir_admin",
          data: JSON.stringify(dvirData)
        }
      })
    }
  }, [flutterApp])

  return (
    <ApplicationLayout>
      <div style={{ marginTop: "-15px", marginLeft: "-10px" }} ref={hostElementRef} />
    </ApplicationLayout>
  )
}

const mapStateToProps = (state) => {
  const { flutterApp } = state.application
  const user = state.application.userSessionData || (state.users && state.users.currentUser)

  return {
    flutterApp: flutterApp,
    currentUser: user
  }
}

export default withRouter(connect(mapStateToProps)(DvirAdmin))
